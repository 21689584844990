var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modSet && _vm.modGroups.length
    ? _c(
        "div",
        {
          staticClass: "store-mod-set-edit row no-gutters",
          attrs: { id: "store-mod-set-edit" },
        },
        [
          _c("context-title", {
            staticClass: "col-12",
            attrs: { title: _vm.title, passedActions: _vm.titleBarActions },
          }),
          _c("line-item", { staticClass: "col-12" }, [
            _c("span", { staticClass: "edit-bar" }),
          ]),
          _c(
            "key-val-line",
            { attrs: { prop: "Enabled" } },
            [
              _c("toggle", {
                attrs: { slider: true },
                model: {
                  value: _vm.modSet.modSetAvailable,
                  callback: function ($$v) {
                    _vm.$set(_vm.modSet, "modSetAvailable", $$v)
                  },
                  expression: "modSet.modSetAvailable",
                },
              }),
            ],
            1
          ),
          _c(
            "key-val-line",
            { attrs: { prop: "Name" } },
            [
              _c("form-input", {
                attrs: { spellcheck: "true" },
                model: {
                  value: _vm.modSet.modSetName,
                  callback: function ($$v) {
                    _vm.$set(_vm.modSet, "modSetName", $$v)
                  },
                  expression: "modSet.modSetName",
                },
              }),
            ],
            1
          ),
          _c("key-val-line", { attrs: { prop: "Mod Groups" } }, [
            _c(
              "div",
              { staticClass: "row no-gutters" },
              [
                _c(
                  "div",
                  { staticClass: "block-xs--md-bottom col-12" },
                  [
                    _c("tiny-explorer", {
                      attrs: {
                        children: _vm.modSet.children,
                        permissions: ["removeChild"],
                        onRemoveChild: _vm.handleRemoveModGroup,
                        sortable: false,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "text-button",
                  {
                    staticClass: "block-xs--md-top col-12",
                    attrs: { type: "add" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.toggleModGroupModal.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Add a mod group")]
                ),
                _vm.modGroupModal
                  ? _c(
                      "editing-modal",
                      { staticClass: "modal" },
                      [
                        _c("editing-modal-header-simple", {
                          attrs: { title: "Select a Mod Group" },
                          on: {
                            closeModal: function ($event) {
                              _vm.modGroupModal = false
                            },
                          },
                        }),
                        _c("add-mod-group", {
                          attrs: {
                            modGroups: _vm.modGroups,
                            permissions: ["addExisting"],
                          },
                          on: { save: _vm.addModGroup },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm.modSet.id
            ? _c("line-item", { staticClass: "col-12 height-xs--12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "row no-gutters height-xs--inherit align-items-center",
                  },
                  [
                    _c(
                      "text-button",
                      {
                        staticClass: "block-xs--sm-left",
                        attrs: { type: "delete" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleDeleteModSet.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Delete set")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.warningActive
            ? _c("edit-warning", {
                attrs: {
                  type: _vm.warningType,
                  effectedItems: _vm.effectedItems,
                  onCancel: _vm.handleEditCancel,
                  onContinue: _vm.handleEditContinue,
                  resolveEffectedTitle: (item) => item.inventoryItemName,
                },
              })
            : _vm._e(),
          _c("unload-confirm", {
            attrs: {
              onExit: _vm.handleLeaveModalExit,
              leaveAction: _vm.leaveConfirmationAction,
              actions: _vm.leaveActions,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }