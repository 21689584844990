<template>
    <div 
        v-if="modSet && modGroups.length" 
        id="store-mod-set-edit" 
        class="store-mod-set-edit row no-gutters">
        <context-title 
            :title="title" 
            :passedActions="titleBarActions" 
            class="col-12" />

        <line-item class="col-12"><span class="edit-bar"/></line-item>

        <key-val-line prop="Enabled">
            <toggle 
                v-model="modSet.modSetAvailable" 
                :slider="true" />
        </key-val-line>

        <key-val-line prop="Name">
            <form-input 
                v-model="modSet.modSetName" 
                spellcheck="true" />
        </key-val-line>

        <key-val-line prop="Mod Groups">
            <div class="row no-gutters">
                <div class="block-xs--md-bottom col-12">
                    <tiny-explorer
                        :children="modSet.children"
                        :permissions="['removeChild']"
                        :onRemoveChild="handleRemoveModGroup"
                        :sortable="false"
                    />
                </div>

                <text-button
                    class="block-xs--md-top col-12"
                    type="add"
                    @click.native="toggleModGroupModal"
                >Add a mod group</text-button>

                <editing-modal 
                    v-if="modGroupModal" 
                    class="modal">
                    <editing-modal-header-simple 
                        title="Select a Mod Group" 
                        @closeModal="modGroupModal = false" />

                    <add-mod-group
                        :modGroups="modGroups"
                        :permissions="['addExisting']"
                        @save="addModGroup"
                    />
                </editing-modal>
            </div>
        </key-val-line>

        <line-item 
            v-if="modSet.id" 
            class="col-12 height-xs--12">
            <div class="row no-gutters height-xs--inherit align-items-center">
                <text-button
                    class="block-xs--sm-left"
                    type="delete"
                    @click.native="handleDeleteModSet">Delete set</text-button>
            </div>
        </line-item>

        <edit-warning
            v-if="warningActive"
            :type="warningType"
            :effectedItems="effectedItems"
            :onCancel="handleEditCancel"
            :onContinue="handleEditContinue"
            :resolveEffectedTitle="item => item.inventoryItemName"
        />

        <unload-confirm
            :onExit="handleLeaveModalExit"
            :leaveAction="leaveConfirmationAction"
            :actions="leaveActions"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { pageUnloadController, editWarningController } from '../mixins'
import ContextTitle from 'components/context_title.vue'
import KeyValLine from 'components/key_val_line.vue'
import TinyExplorer from 'components/tiny_explorer/explorer'
import Toggle from 'components/cleverly/Toggle.vue'
import FormInput from 'components/cleverly/FormInput.vue'
import LineItem from 'components/line_item.vue'
import TextButton from 'components/text_button.vue'
import EditWarning from 'components/edit_warning'
import EditingModal from 'components/editing_modal'
import EditingModalHeaderSimple from 'components/editing_modal_header_simple'
import AddModGroup from 'components/add_mod_group'

export default {
    name: 'StoreModSetEdit',
    mixins: [pageUnloadController, editWarningController],
    data() {
        return {
            activeGroups: ['12345'],
            modGroupModal: false,
            modSet: false,
            titleBarActions: [
                {
                    type: 'cancel',
                    display: 'Cancel',
                    run: () => {
                        this.$router.push({name: 'store-mod-index'})
                    },
                },
                {
                    type: 'proceed',
                    display: 'Save',
                    run: () => {
                        this.changed = false

                        this.save()
                        this.$router.push({name: 'store-mod-index'})
                    },
                },
            ],
        }
    },
    computed: {
        title() {
            return [
                {to: {name: 'store-mod-index'}, label: 'Mod Set'},
                (this.modSet.title || 'New Mod Set'),
            ]
        },
        modGroups() {
            return this.products
                .reduce((sum, next) => {
                    return sum.concat(next.modGroups)
                }, [])
        },
        associatedProducts() {
            return this.modSet.inventoryItemIDs
                .map(productId => {
                    return this.products.find(el => el.inventoryItemID === productId)
                })
                .filter(el => el !== undefined)
        },
        ...mapGetters({
            products: 'products',
            modSets: 'modSets',
        }),
    },
    methods: {
        setModSet() {
            if (this.modSets && !this.modSet) {
                const setId = this.$route.params.set

                const base = {
                    modSetAvailable: true,
                    modSetName: '',
                    modSetID: '',
                    modGroups: [],
                    inventoryItemIDs: [],
                    bRemoveModSetItemBeforeCreation: false,
                    modSetStartTime: '1/1/1900 3:00 AM',
                    modSetEndTime: '1/1/1900 11:00 PM',
                    modSetOrder: '1',
                    children: [],
                }

                this.modSet = this.$route.params.set ? this.modSets.find(set => set.id === setId) : base
            }
        },
        toggleActive(id) {
            if (this.activeGroups.indexOf(id) === -1) {
                this.activeGroups.push(id)
            } else {
                this.activeGroups.splice(this.activeGroups.indexOf(id), 1)
            }
        },
        isActiveGroup(id) {
            return this.activeGroups.indexOf(`${id}`) !== -1
        },
        toggleModGroupModal() {
            if (this.modGroupModal) {
                this.modGroupModal = false
                return
            }

            this.generateWarning('mod set', this.associatedProducts, () => {
                this.modGroupModal = true
            })
        },
        addModGroup(modGroup) {
            if (this.modSet.children.find(group => group.id === modGroup.id)) {
                this.toast('You have already selected that mod group.')
            } else {
                this.modSet.children = this.modSet.children.concat([modGroup])
            }

            this.modGroupModal = false
        },
        deleteModGroup(modGroup) {
            this.modSet.children = this.modSet.children
                .filter(group => group.id !== modGroup.id)
        },
        handleRemoveModGroup(modGroup) {
            this.generateWarning('mod group', this.associatedProducts, () => {
                this.deleteModGroup(modGroup)
            })
        },
        deleteModSet() {
            this.changed = false

            const modSet = Object.assign(this.modSet, {
                children: [],
                inventoryItemIDs: [],
            })

            this.saveModSets([modSet])
            this.$router.push({name: 'store-mod-index'})
            this.toast('Mod set deleted.')
        },
        handleDeleteModSet() {
            this.generateWarning('mod set', this.associatedProducts, () => {
                this.deleteModSet()
            })
        },
        save() {
            this.saveModSets([this.modSet])
        },
        resolvePageTitle() {
            if (this.modSet) {
                if (this.modSet.id) {
                    document.title = 'Edit ' + this.modSet.modSetName
                } else {
                    document.title = 'New Mod Set'
                }
            }
        },
        ...mapActions([
            'saveModSets',
        ]),
    },
    components: {
        ContextTitle,
        KeyValLine,
        TinyExplorer,
        Toggle,
        FormInput,
        LineItem,
        TextButton,
        EditWarning,
        EditingModal,
        EditingModalHeaderSimple,
        AddModGroup,
    },
    watch: {
        modSet: {
            handler() {
                this.handleChange()
            },
            deep: true,
        },
        modSets: {
            handler() {
                this.resolvePageTitle()
                this.setModSet()
            },
            deep: true,
        },
    },
    created() {
        this.setModSet()
        this.resolvePageTitle()
    },
}
</script>

<style lang="scss">
    @import '~scss/variables';
    .store-mod-set-edit {
        &__add-group {
            color: $brand_color;
            display: block;
            &:hover {
                color: darken($brand_color, 5%);
            }
        }
    }
</style>
